<template>
    <div>

        <div class="header pb-8 pt-2 pt-lg-6 d-flex align-items-center">
            <!-- Mask -->

            <!-- Header container -->
            <div class="container-fluid d-flex align-items-center">

                <div class="col-lg-9 p-0 col-md-10">
                    <h3 class="mb-0 text-white"><span v-if="this.id !== 'add'">Upravuje sa </span><span v-else>Vytvárate </span><span class="text-lowercase">blok</span></h3>
                    <h2 class="" v-if="block.title">{{block.title}}</h2>
                    <h2 class="" v-else>{{block.des}}</h2>
                </div>
            </div>
        </div>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col-xl-9 col-md-9 col-lg-12  order-xl-1">
                    <a-card shadow type="secondary">
                        <template>
                            <form @submit.prevent="handleSubmit">
                                <div class="pl-lg-4">
                                    <div class="row">
                                        <div class="col-lg-12">
                                          <label>Názov</label>
                                            <a-input alternative=""
                                                        label="Názov"
                                                        input-classes="form-control-alternative"
                                                        v-model="block.title"
                                            />
                                          <label>Popis</label>
                                            <a-input alternative=""
                                                        label="Popis"
                                                        input-classes="form-control-alternative"
                                                        v-model="block.des"
                                            />
                                            <label>URL prefix</label>
                                            <a-input alternative=""
                                                        input-classes="form-control-alternative"
                                                        v-model="block.urlprefix"
                                            />
                                            <div class="mb-4">
                                                <label class="form-control-label">BetaEditor</label>

                                                <builder v-if="block.builder.length > 0 || id === 'add'" @changed="(ee) => onChildChangeBuilder(ee,'builder')" :ct="block.showon" :vc="block.vocabulary" :data="block" :values="block.builder"></builder>
                                            </div>

                                            <template>
                                                <div>
                                                    <h6 class="heading-small text-muted">Priradiť pod obsah typu</h6>
                                                    <a-radio-group  class="mb-3" :options="contenttypes" v-model="block.showon"/>
                                                </div>
                                            </template>
                                            <template>
                                                <div>
                                                    <h6 class="heading-small text-muted mb-4">Priradiť pod taxonómiu</h6>
                                                    <a-radio-group  class="mb-3" :options="taxonomies" v-model="block.vocabulary"/>
                                                </div>
                                            </template>
                                            <template>
                                                <div>
                                                    <h6 class="heading-small text-muted">Zobraziť na stránkach </h6>
                                                    <a-textarea v-model="block.pages"></a-textarea>
                                                    <div class="text-muted fs-10 mb-3">(tvar: node/xxx jednotlivé stránky odenterovať)</div>
                                                </div>
                                            </template>
                                            <template>
                                                <div>
                                                    <h6 class="heading-small text-muted">Schovať na stránkach</h6>
                                                    <a-textarea v-model="block.notpages"></a-textarea>
                                                    <div class="text-muted fs-10 mb-3">(tvar: node/xxx jednotlivé stránky odenterovať)</div>
                                                </div>
                                            </template>
                                            <h6 class="heading-small text-muted">Sekcia</h6>
                                            <a-radio-group  class="mb-3" :options="sections" v-model="block.section"/>
                                            <div class="mb-4" >
                                              <h6 class="heading-small text-muted">Jazyk</h6>
                                                <a-select style="width: 100%"  v-model="block.language" :options="languages"/>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="text-right">
                                    <button class="btn btn-primary">Uložiť</button>
                                </div>
                            </form>
                        </template>
                    </a-card>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {getURL} from "../api";
    import {authHeader} from "../_helpers";
    import {dataService} from "../_services";
    import Vue from "vue"
    import FormItem from 'ant-design-vue'
    import Image from "./content/Image";
    import {Cropper} from 'vue-advanced-cropper'
    import Builder from "./content/Builder";
    import ATextarea from "ant-design-vue/es/input/TextArea";

    Vue.use(FormItem);
    Vue.use(Cropper);


    export default {
        name: 'block',
        components: {ATextarea, Builder, 'image-upload' : Image},
        props: ['id'],
        data() {
            return {
                url: getURL(),
                fieldData: {},
                block:{'language':'','title':'','description':'','nodeshow':'','builder': []},
                formData: null,
                fileList: [],
                contenttypes:[],
                languages:[],
                taxonomies:[],
                head: authHeader(),
                types: [
                    {"value": '1', "label": 'Text'},
                    {"value": '2', "label": 'Výberovník'},
                    {"value": '3', "label": 'Obrázok'},
                    {"value": '4', "label": 'Dlhý text'},
                    {"value": '5', "label": 'Celé číslo'},
                    {"value": '6', "label": 'Desatiné číslo'},
                    {"value": '7', "label": 'Logická hodnota Áno/Nie'},
                ],
                sections: [
                    {"value": '0', "label": 'Nepriradené (0)'},
                    {"value": '7', "label": 'Top stránky (7)'},
                    {"value": '1', "label": 'Hlavička stránky  (1)'},
                    {"value": '2', "label": 'Bannerová časť stránky  (2)'},
                    {"value": '3', "label": 'Hlavná sekcia  (3)'},
                    {"value": '4', "label": 'Ľavá časť stránky  (4)'},
                    {"value": '5', "label": 'Pravá časť stránky  (5)'},
                    {"value": '6', "label": 'Päta stránky (6)'},
                ],
                ct: null,
                colorsto: JSON.parse(localStorage.colors),
                formItemLayout: {
                    labelCol: {
                        xs: {span: 24},
                        sm: {span: 4},
                    },
                    wrapperCol: {
                        xs: {span: 24},
                        sm: {span: 20},
                    },
                },
                formItemLayoutWithOutLabel: {
                    wrapperCol: {
                        xs: {span: 24, offset: 0},
                        sm: {span: 20, offset: 4},
                    },
                },
                head: authHeader(),
                contenttypeId: {
                    uid: this.id
                },
                options: [],
                contenttype: {
                    title: '',
                    type: '',
                },
            }
        },
        beforeCreate() {
            this.form = this.$form.createForm(this, {name: 'dynamic_form_item'});
            this.form.getFieldDecorator('keys', {initialValue: [], preserve: true});
        },
        methods: {
            onChildChangeBuilder(value,slug) {
                this.block.builder = JSON.stringify(value); // someValue
            },
            bool(v) {
                return v === "true" || v === "1";
            },
            handleSubmit() {

                dataService.axiosPost(this.$options.name + "/" + this.id, this.block).then(results => {
                    if (results.data) {
                        this.$message.success('Obsah bol upravený');
                        if(isNaN(this.id))
                        {
                            this.$router.push('/block/'+results.data[0])
                        }
                    } else {
                        this.$message.error('Pri ukladaní nastala chyba');
                    }
                });
            },
            handleChange(info,slug) {
                this.fieldData[slug] = null;
                if (info.file.status === 'done') {
                    this.fieldData[slug] = info.file.response[0]
                } else if (info.file.status === 'error') {
                    this.fieldData[slug] = ""
                }
            },
        },
        mounted() {
            if (this.id && this.id !== "add") {
                dataService.axiosFetch("block", this.id).then(results => {
                    this.block = results;
                });

            }
            dataService.axiosFetch("contenttypes").then(results => {
                var roleops = [];
                roleops.push({"value" : '' , "label" : 'Bez priradenia'});
                results.forEach(obj => { roleops.push({"value" : obj.slug , "label" : obj.title}); });

                this.contenttypes = roleops;
            });
                dataService.axiosFetch('languages').then(results => {
                var roleops = [];
                roleops.push({"value" : '' , "label" : 'Bez jazyka'});
                results.forEach(obj => { roleops.push({"value" : obj.short , "label" : obj.title}); });
 this.languages = roleops;
            });

            dataService.axiosFetch("taxonomies").then(results => {
                var roleops = [];
                roleops.push({"value" : '' , "label" : 'Bez priradenia'});
                results.forEach(obj => { roleops.push({"value" : obj.slug , "label" : obj.title}); });

                this.taxonomies = roleops;
            });
        }
    };
</script>
<style scoped>

    .input-group .input-group-prepend .input-group-text {
        border: 0;
    }

    .dynamic-delete-button {
        cursor: pointer;
        position: relative;
        top: 4px;
        font-size: 24px;
        color: #999;
        transition: all 0.3s;
    }

    .add-button i
    {
        position: relative;
        top: -3px;}
    .dynamic-delete-button:hover {
        color: #777;
    }

    .dynamic-delete-button[disabled] {
        cursor: not-allowed;
        opacity: 0.5;
    }

    .ant-select-selection--single {
        width: 100%;
        height: calc(1.5em + 1.25rem + 2px);
        padding: 0.625rem 0.75rem;
    }
    h2.display-2.text-white
    {min-height:65px}

    .description
    {margin-top:-40px;display:inline-block;font-size:14px;color:rgba(0,0,0,.3);
        margin-bottom:-20px;position:relative;top:-20px;left:5px}
</style>
